import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { motion } from "framer-motion";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";
const ProjectCard = ({ data, isImageOnly }) => {
	return (
		<motion.div initial={{ scale: 0.7 }}
			whileInView={{ scale: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="relative w-full h-full overflow-hidden group">
			<img
				src={data?.img}
				alt="project"
				className="w-full h-full object-cover object-center"
			/>
			{
				!isImageOnly && (
					<Link to={ROUTES.portfolioDetails}>
						<motion.div initial={{ scale: 0.7 }}
							whileInView={{ scale: 1 }}
							transition={{ duration: 1, type: "spring", stiffness: 100 }} className="hidden group-hover:flex absolute top-0 left-0 w-full h-full bg-troo-secondary bg-opacity-75 flex-col items-center justify-end p-6 sm:p-8 lg:p-12">
							<div className="relative flex w-full border border-troo-light flex-col items-start rounded-lg p-4 sm:p-5 lg:p-6 bg-troo-primary text-troo-light">
								<p>{data?.category}</p>
								<h5>{data?.title}</h5>
								<div className="absolute -top-5 right-8 bg-troo-primary text-troo-light border border-troo-light p-3 rounded-md">
									<BsPlusLg />
								</div>
							</div>
						</motion.div>
					</Link>
				)
			}
		</motion.div>
	);
};

export default ProjectCard;
