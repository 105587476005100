import React from 'react'
import { IoMdArrowForward } from "react-icons/io";

const ListItemWithNum = ({ txt, no }) => {
  return (
    <div className='w-full flex items-center justify-between group hover:text-troo-primary transition-all'>
      <div className='flex items-center gap-3'>
        <p>{no}</p>
        <p>{txt}</p>
      </div>
      <IoMdArrowForward className='group-hover:rotate-45' />
    </div>
  )
}

export default ListItemWithNum