import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import ServiceSection from '../sections/ServiceSection'

const OurServices = () => {
  return (
    <Layout>
      <SubHeroSection title="What We Do" />
      <ServiceSection isListingPage/>
    </Layout>
  )
}

export default OurServices