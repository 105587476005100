import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import BlogSection from '../sections/BlogSection'

const OurBlogs = () => {
  return (
    <Layout>
      <SubHeroSection title="Our Blogs" />
      <BlogSection isListingPage />
    </Layout>
  )
}

export default OurBlogs