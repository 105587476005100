import React from "react";

const SolutionCard = ({ id, title }) => {
	return (
		<div className="w-full p-8 rounded-lg flex flex-col items-start gap-4 bg-troo-light">
			<div className="aspect-square bg-troo-primary p-2.5 font-semibold rounded-full text-troo-light w-9 h-9 flex items-center justify-center">
				{id}
			</div>
			<p className="font-secondary text-xl text-troo-secondary font-bold">
				{title}
			</p>
			<p className="text-troo-secondary">
				There are many variations of passages of Lorem Ipsum available, but the
				majority have suffered alteration in some.
			</p>
		</div>
	);
};

export default SolutionCard;
