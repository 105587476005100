import React from 'react'
import { FaRegUser } from "react-icons/fa6";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { ROUTES } from '../constants/routes';
import { Link } from 'react-router-dom';

const BlogCard = ({ data }) => {
  return (
    <motion.div initial={{ y: 100 }}
      whileInView={{ y: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }} className="w-full flex flex-col rounded-xl overflow-hidden group cursor-pointer">
      <div className="w-full">
        <Link to={ROUTES.blogDetails}>
          <img src={data?.img} alt="img" className="w-full" />
        </Link>
      </div>
      <div className="relative w-full bg-troo-light flex flex-col items-center text-center gap-5">
        <div className="absolute -top-8 left-1/2 -translate-x-1/2 w-[90%] flex sm:flex-row flex-col items-center gap-3 py-4 px-7 bg-troo-light text-troo-secondary rounded-lg shadow-xl text-xs sm:text-sm md:text-base">
          <div className="flex w-full lg:border-r border-troo-secondary justify-center items-center gap-3">
            <FaRegUser />
            <p className='text-xs sm:text-sm md:text-base'>{data?.author}</p>
          </div>
          <div className="flex w-full items-center justify-center gap-3 ">
            <IoCalendarNumberOutline />
            <p className='text-xs sm:text-sm md:text-base'>{data?.date}</p>
          </div>
        </div>
        <h5 className='mt-16 sm:mt-12 mb-2 px-5'>{data?.title}</h5>
        <Link to={ROUTES.blogDetails} className='w-full'>
          <button className="w-full bg-troo-light group-hover:bg-troo-primary group-hover:text-troo-light p-3 rounded-b-xl text-troo-primary border-b border-troo-primary">Read Full Blog</button>
        </Link>
      </div>
    </motion.div>
  )
}

export default BlogCard