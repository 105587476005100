import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import { FaQuoteLeft } from "react-icons/fa";
import BlogImg from "../assets/images/blog-detail-img.png"
import BlogImg2 from "../assets/images/project-4.png"
import BlogImg3 from "../assets/images/project-5.png"
import { FaCircleArrowRight } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BLOGS, PROJECTS } from '../constants';
import ProjectCard from '../components/ProjectCard';
import BlogCard from '../components/BlogCard';

const BlogDetails = () => {
  return (
    <Layout>
      <SubHeroSection title="Blog Details" />
      <div className="text-troo-secondary py-[100px] w-full flex flex-col items-center">
        <div className="main-container-big bg-troo-light w-full flex flex-col items-start gap-8">
          <div className="flex items-center gap-4 flex-wrap">
            <span>24 June, 2023</span>
            <span>|</span>
            <span>EV Charging, Charging Solution</span>
            <span>|</span>
            <span>0 Comment</span>
          </div>
          <h5>World Environment Day: How EV Charging Helps Reduce Emissions</h5>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
          <div className="flex flex-col items-start gap-3 w-[90%] self-center">
            <FaQuoteLeft className='text-4xl text-troo-secondary text-opacity-20' />
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
            <p className='text-xl font-secondary text-troo-primary font-medium'>Robert Dilsoon</p>
          </div>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, Lorem Ipsum, you need to be sure there isn't or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.</p>
          <img src={BlogImg} alt="car" className="w-full rounded-lg" />
          <h5>Charging Helps Reduce Emissions</h5>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
          <h5>How EV Charging Helps Reduce Emissions</h5>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator.</p>
          <div className='flex flex-col items-start gap-3'>
            <div className='flex items-center gap-3'>
              <div>
                <FaCircleArrowRight className='text-troo-primary text-lg md:text-xl' />
              </div>
              <p className='text-sm md:text-base'>Do not mind anything that anyone tells you about anyone else. Judge everyone and everything for yourself</p>
            </div>
            <div className='flex items-center gap-3'>
              <div>
                <FaCircleArrowRight className='text-troo-primary text-lg md:text-xl' />
              </div>
              <p className='text-sm md:text-base'>Do not mind anything that anyone tells you about anyone else. Judge.</p>
            </div>
            <div className='flex items-center gap-3'>
              <div>
                <FaCircleArrowRight className='text-troo-primary text-lg md:text-xl' />
              </div>
              <p className='text-sm md:text-base'>Do not mind anything that anyone tells Judge everyone and everything for yourself</p>
            </div>
            <div className='flex items-center gap-3'>
              <div>
                <FaCircleArrowRight className='text-troo-primary text-lg md:text-xl' />
              </div>
              <p className='text-sm md:text-base'>Do not mind anything that anyone tells you about anyone else. Judge everyone and everything.</p>
            </div>
          </div>
          <div className='w-full grid grid-cols-2 gap-3'>
            <img src={BlogImg2} alt="car" className="w-full rounded-lg" />
            <img src={BlogImg3} alt="car" className="w-full rounded-lg" />
          </div>
          <h5>Reduce Emissions</h5>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator.</p>
          <div className='p-6 rounded-lg flex flex-wrap items-center gap-6 bg-troo-primary-light w-full text-troo-secondary border-b border-troo-primary'>
            <p>Share This Blog</p>
            <div className="flex items-center gap-3">
              <div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-secondary">
                <FaFacebookF />
              </div>
              <div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-secondary">
                <FaXTwitter />
              </div>
              <div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-secondary">
                <FaLinkedinIn />
              </div>
              <div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-secondary">
                <FaInstagram />
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col items-start gap-8">
            <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-6 mt-10'>
              <div className='flex flex-col items-start gap-3'>
                <p className='text-sm md:text-base'>Full Name</p>
                <input type='text' placeholder='Full Name' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
              <div className='flex flex-col items-start gap-3'>
                <p className='text-sm md:text-base'>Phone Number</p>
                <input type='text' placeholder='Phone Number' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
              <div className='flex flex-col items-start gap-3'>
                <p className='text-sm md:text-base'>Email Address</p>
                <input type='text' placeholder='Email Address' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
              <div className='flex flex-col items-start gap-3 md:col-span-2 lg:col-span-3'>
                <p className='text-sm md:text-base'>Message</p>
                <textarea rows={6} placeholder='Message' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
            </div>
            <button className='btn-theme-primary'>POST COMMENT</button>
          </div>
          <h5>Related Blogs</h5>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {BLOGS.map((data, i) => {
              return (
                <BlogCard data={data} index={i} />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetails