import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import TeamSection from '../sections/TeamSection'

const OurTeam = () => {
  return (
    <Layout>
      <SubHeroSection title="Our Team" />
      <TeamSection isListingPage />
    </Layout>
  )
}

export default OurTeam