import serviceImg1 from "../assets/images/service-1.png";
import serviceImg2 from "../assets/images/service-2.png";
import serviceImg3 from "../assets/images/service-3.png";

import ProjectImg1 from "../assets/images/project-1.png";
import ProjectImg2 from "../assets/images/project-2.png";
import ProjectImg3 from "../assets/images/project-3.png";
import ProjectImg4 from "../assets/images/project-4.png";
import ProjectImg5 from "../assets/images/project-5.png";

import Team1 from "../assets/images/Team-Member-1.png";
import Team2 from "../assets/images/Team-Member-2.png";
import Team3 from "../assets/images/Team-Member-3.png";

import TestimonialImg1 from "../assets/images/Testimonial-Reviewer-1.png";
import TestimonialImg2 from "../assets/images/Testimonial-Reviewer-2.png";

export const SERVICE = [
	{
		img: serviceImg1,
		title: "Home Charging",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
	},
	{
		img: serviceImg2,
		title: "Public Charging",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
	},
	{
		img: serviceImg3,
		title: "Commercial System",
		desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
	},
];

export const PROJECTS = [
	{
		img: ProjectImg1,
		title: "Electric car charging home",
		category: "Charging Installation",
	},
	{
		img: ProjectImg2,
		title: "Transport And Energy",
		category: "Technology",
	},
	{
		img: ProjectImg3,
		title: "EV Charging Station",
		category: "Ecology, Technology",
	},
	{
		img: ProjectImg4,
		title: "Electric Car Application",
		category: "Auto Mobile",
	},
	{
		img: ProjectImg5,
		title: "Electric Car Charging",
		category: "Auto Mobile, Ecology",
	},
];

export const TEAM = [
	{
		img: Team1,
		title: "Erik Rowanson",
		designation: "Company Director",
	},
	{
		img: Team2,
		title: "Erik Rowanson",
		designation: "Company Manager",
	},
	{
		img: Team3,
		title: "Erik Rowanson",
		designation: "Chief Engineer",
	},
];

export const TESTIMONIAL = [
	{
		img: TestimonialImg1,
		txt: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
		name: "Alexa Christian",
		city: "Los Angeles"
	},
	{
		img: TestimonialImg2,
		txt: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
		name: "Dwen Tumody",
		city: "Los Angeles"
	},
];

export const BLOGS = [
	{
		img: ProjectImg4,
		title: "World Environment Day: How EV Charging Helps Reduce Emissions",
		date: "24 June, 2023",
		author: "Renny Young"
	},
	{
		img: ProjectImg1,
		title: "Understanding Lithium-Ion Batteries And EV Charging",
		date: "24 June, 2023",
		author: "Renny Young"
	},
	{
		img: ProjectImg2,
		title: "How To Charge An EV Without A Driveway: A Comprehensive Guide",
		date: "24 June, 2023",
		author: "Renny Young"
	},
]