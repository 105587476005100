import React from "react";
import { BsFillPlayFill } from "react-icons/bs";
import MobileImg from "../assets/images/Mobile.png"
import AppStore from "../assets/images/App-Store-Badge.svg"
import GooglePlayStore from "../assets/images/Google-Play-Badge.svg"
import Heading from "../components/Heading";
import ListItem from "../components/ListItem";

const VideoBannerSection = ({ isAppDownloadBanner }) => {
	return (
		<div className="text-troo-secondary w-full flex flex-col items-center">
			<div className="bg-video-banner-main bg-no-repeat bg-cover bg-center w-full">
				<div className="w-full bg-troo-secondary bg-opacity-70 min-h-[500px] flex flex-col items-center justify-center">
					<div className="bg-troo-light text-troo-primary text-5xl p-5 rounded-full">
						<BsFillPlayFill />
					</div>
				</div>
			</div>
			{
				isAppDownloadBanner && (
					<div className="main-container-big bg-transparent w-full items-center -mt-16">
						<div className="w-full grid md:grid-cols-2 items-center bg-troo-light rounded-xl gap-8 md:p-16 p-6 shadow-2xl">
							<div className="w-full bg-download-banner-map flex items-center justify-center p-10">
								<img src={MobileImg} alt="mobile" className="" />
							</div>
							<div className="flex flex-col items-start gap-6">
								<Heading headline="Awesome Client’s Reviews" title="2.400 public charging points
in your app" alignStart />
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been Lorem the Loremindustry's standard dummy text ever </p>
								<div className="flex flex-col items-start gap-4">
									<ListItem txt="Lorem Ipsum is simply dummy text " />
									<ListItem txt="Lorem Ipsum is simply dummy text " />
									<ListItem txt="Lorem Ipsum is simply dummy text " />
								</div>
								<div className="flex items-center gap-4 flex-wrap justify-center">
									<img src={AppStore} alt="appstore" className="lg:w-auto w-32" />
									<img src={GooglePlayStore} alt="play store" className="lg:w-auto w-32" />
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default VideoBannerSection;
