import React from 'react'
import Heading from '../components/Heading'
import Img1 from "../assets/images/logo1.png"
import Img2 from "../assets/images/logo2.png"
import Img3 from "../assets/images/logo3.png"
import Img4 from "../assets/images/logo4.png"
import Img5 from "../assets/images/logo5.png"
import Img6 from "../assets/images/logo6.png"
import Img7 from "../assets/images/logo7.png"
import Img8 from "../assets/images/logo8.png"
import Img9 from "../assets/images/logo9.png"
import Img10 from "../assets/images/logo10.png"

const BrandSection = () => {
  return (
    <div className="text-troo-secondary w-full flex flex-col items-center">
      <div className="main-container-big bg-troo-light w-full py-[100px] lg:py-10 flex lg:flex-row flex-col items-center gap-8">
        <div className="flex flex-col items-start gap-4 w-full">
          <Heading alignStart headline="Awesome Client’s Reviews" title={<>
            We collabrate 250+ <br />
            company & Organization
          </>} />
          <p>There are many variations of passages of Lorem Ipsum<br /> available, but the majority have suffered alteration in some<br /> form, by injected humour.</p>
          <p>Want to become our partner? <span className="text-troo-primary font-bold">Contact With Us</span></p>
        </div>
        <div className="md:w-full lg:w-auto bg-none lg:bg-trans bg-center bg-no-repeat lg:py-[100px]  grid grid-cols-2 sm:flex items-center justify-center flex-wrap gap-2">
          <img src={Img1} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img2} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img3} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img4} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img5} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img6} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img7} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img8} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img9} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
          <img src={Img10} alt="logo" className='max-w-full sm:max-w-[192px] sm:max-h-[100px]' />
        </div>
      </div>
    </div>
  )
}

export default BrandSection