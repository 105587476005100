import React from "react";
import About1 from "../assets/images/about-1.png";
import About2 from "../assets/images/about-2.png";
import { BsArrowRightCircle, BsFuelPumpDiesel } from "react-icons/bs";
import Heading from "../components/Heading";
import ListItem from "../components/ListItem";

const AboutSection = () => {
  return (
    <div className="text-troo-secondary py-[100px] w-full flex flex-col items-center">
      <div className="main-container-big bg-troo-light w-full grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="grid w-full grid-cols-2 gap-3">
          <div className="w-full">
            <img
              src={About1}
              alt="about1"
              className="w-full h-full object-cover object-center"
            />
          </div>
          <div className="flex flex-col items-center w-full gap-3">
            <div className="flex flex-col items-center px-5 py-8 w-full gap-4 rounded-lg bg-troo-primary text-troo-light">
              <BsFuelPumpDiesel className="text-7xl" />
              <p className="text-center">
                We have a strong focus on
                <br /> sustainability and on the customer at
                <br /> all stages.
              </p>
            </div>
            <img
              src={About2}
              alt="about2"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-8">
          <Heading
            alignStart
            headline="Learn About Us"
            title="We offer smart charging solutions tailored to your business model needs"
          />
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don’t look even slightly
            believable.
          </p>
          <div className="grid grid-cols-2 gap-5">
            <div className="w-full flex flex-col items-start gap-4">
              <div className="flex items-center gap-2">
                <BsArrowRightCircle className="text-2xl" />
                <p className="font-bold font-secondary text-xl">Our Mission</p>
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available.
              </p>
            </div>
            <div className="w-full flex flex-col items-start gap-4">
              <div className="flex items-center gap-2">
                <BsArrowRightCircle className="text-2xl" />
                <p className="font-bold font-secondary text-xl">Our Vision</p>
              </div>
              <p>
                There are many variations of passages of Lorem Ipsum available.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start gap-3">
            <ListItem txt="Monitor your charging and earn rewards by charging" />
            <ListItem txt="Hundreds of thousands of places to charge" />
            <ListItem txt="Reducing CO2 emissions by manufacturing locally" />
          </div>
          <button className="btn-theme-primary">DISCOVER MORE</button>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
