import React from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import EVCar from "../assets/images/ev-car.png";

const HeroSection = () => {
	return (
		<div className="relative py-[100px] bg-troo-primary-light bg-cover bg-no-repeat bg-center text-troo-secondary w-full after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:bg-hero-shape-2 after:bg-no-repeat before:bg-no-repeat after:bg-right-top before:content-[''] before:absolute before:top-0 before:left-0 before:w-full after:h-full before:h-full before:bg-hero-shape-1 before:bg-left-top after:hidden md:after:block">
			<div className="main-container w-full flex lg:flex-row flex-col items-center gap-5 justify-between">
				<div className="flex flex-col items-start gap-3 z-20">
					<div className="flex items-center gap-2">
						<span className="text-2xl text-troo-primary">
							<BsFillLightningChargeFill />
						</span>
						<span>Welcome to TRoo Themes</span>
					</div>
					<h2 className="text-5xl lg:text-7xl">
						Doing the future good for the environment
					</h2>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable.
					</p>
					<div className="flex items-center gap-4">
						<button className="btn-theme-primary">Learn More</button>
						<button className="btn-theme-secondary">Contact Us</button>
					</div>
				</div>
				<div className="w-full h-full z-50">
					<img src={EVCar} className="w-full" alt="car" />
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
