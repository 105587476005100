import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { motion } from "framer-motion";

const TestimonialCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="w-full rounded-lg bg-troo-light p-8 flex flex-col gap-5 items-start">
			<img src={data?.img} className="-mt-20" alt={data?.name} />
			<p>{data?.txt}</p>
			<div className="w-full h-[1px] bg-troo-secondary bg-opacity-20" />
			<div className="w-full flex items-center justify-between">
				<div className="flex flex-col items-start">
					<div className="flex items-center gap-3">
						<div className="w-11 h-[2px] bg-troo-primary" />
						<h4 className="font-secondary font-bold">{data?.name}</h4>
					</div>
					<p className="ml-14">{data?.city}</p>
				</div>
				<div className="aspect-square p-3 rounded-full bg-troo-primary text-xl text-troo-light relative after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-6 after:h-6 after:bg-troo-primary after:-z-10 z-10">
					<FaQuoteLeft />
				</div>
			</div>
		</motion.div>
	);
};

export default TestimonialCard;
