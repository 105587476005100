export const ROUTES = {
	home: "/",
	aboutUs: "/about-us",
	ourServices: "/our-services",
	serviceDetails: "/our-services/service-details",
	ourTeam: "/our-team",
	portfolio: "/portfolio",
	portfolioDetails: "/portfolio/portfolio-details",
	testimonials: "/testimonials",
	faqs: "/faqs",
	contactUs: "/contact-us",
	ourBlogs: "/our-blogs",
	blogDetails: "/our-blogs/blog-details",
	errorPage: "/404",
};
