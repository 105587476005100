import React from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ServiceCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="w-full rounded-lg overflow-hidden flex flex-col items-start border-t-2 border-troo-primary group">
			<div className="w-full p-8  bg-troo-light flex text-troo-secondary group-hover:bg-troo-primary group-hover:text-troo-light transition-all duration-100 ease-linear flex-col items-start gap-4">
				<Link to={ROUTES.serviceDetails}><h4 className="underline underline-offset-2">{data?.title}</h4></Link>
				<p>{data?.desc}</p>
				<div className="flex items-center gap-2">
					<p>Read More</p>
					<BsArrowRightCircle />
				</div>
			</div>
			<div className="w-full">
				<Link to={ROUTES.serviceDetails}>
					<img
						src={data?.img}
						className="w-full object-cover object-center"
						alt="service car"
					/>
				</Link>
			</div>
		</motion.div>
	);
};

export default ServiceCard;
