import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./shared/constants/routes";
import Home from "./shared/pages/Home";
import About from "./shared/pages/About";
import OurServices from "./shared/pages/OurServices";
import Portfolio from "./shared/pages/Portfolio";
import OurBlogs from "./shared/pages/OurBlogs";
import OurTeam from "./shared/pages/OurTeam";
import ErrorPage from "./shared/pages/ErrorPage";
import ContactUs from "./shared/pages/ContactUs";
import ServiceDetails from "./shared/pages/ServiceDetails";
import PortfolioDetails from "./shared/pages/PortfolioDetails";
import BlogDetails from "./shared/pages/BlogDetails";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={ROUTES.home} element={<Home />} />
				<Route path={ROUTES.aboutUs} element={<About />} />
				<Route path={ROUTES.ourServices} element={<OurServices />} />
				<Route path={ROUTES.portfolio} element={<Portfolio />} />
				<Route path={ROUTES.ourBlogs} element={<OurBlogs />} />
				<Route path={ROUTES.ourTeam} element={<OurTeam />} />
				<Route path={ROUTES.errorPage} element={<ErrorPage />} />
				<Route path={ROUTES.contactUs} element={<ContactUs />} />
				<Route path={ROUTES.serviceDetails} element={<ServiceDetails />} />
				<Route path={ROUTES.portfolioDetails} element={<PortfolioDetails />} />
				<Route path={ROUTES.blogDetails} element={<BlogDetails />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
