import React from "react";
import Heading from "../components/Heading";
import Img1 from "../assets/images/Step-1.png";
import Img2 from "../assets/images/Step-3.png";
import Img3 from "../assets/images/Step-4.png";
import Img4 from "../assets/images/Step-2.png";
import Arrow from "../assets/images/arrow.png";

const HowItWorks = () => {
	return (
		<div className="text-troo-secondary bg-troo-light py-[100px] w-full flex flex-col items-center">
			<div className="main-container w-full flex flex-col items-center gap-10">
				<Heading
					headline="How It Works"
					title={
						<>
							Fuel station & maintenance for <br />
							all vehicles
						</>
					}
				/>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:flex items-start gap-8 lg:gap-0 justify-between">
					<div className="flex flex-col items-center text-center gap-1 text-troo-secondary">
						<img src={Img1} alt="img1" className="" />
						<h4>Obtaining Permits</h4>
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry.
						</p>
					</div>
					<img src={Arrow} alt="arrow" className="lg:block hidden mt-12" />
					<div className="flex flex-col items-center text-center gap-1 text-troo-secondary">
						<img src={Img2} alt="img1" className="" />
						<h4>Confirm Electrical Panel</h4>
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry.
						</p>
					</div>
					<img src={Arrow} alt="arrow" className="lg:block hidden mt-12" />
					<div className="flex flex-col items-center text-center gap-1 text-troo-secondary">
						<img src={Img3} alt="img1" className="" />
						<h4>Install EV Charger</h4>
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry.
						</p>
					</div>
					<img src={Arrow} alt="arrow" className="lg:block hidden mt-12" />
					<div className="flex flex-col items-center text-center gap-1 text-troo-secondary">
						<img src={Img4} alt="img1" className="" />
						<h4>Enjoy Charger Feature</h4>
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting
							industry.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HowItWorks;
