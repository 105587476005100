import React from "react";
import Heading from "../components/Heading";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { PROJECTS } from "../constants";
import ProjectCard from "../components/ProjectCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ProjectSection = ({ isListingPage }) => {
	return (
		<div className={`text-troo-secondary py-[100px] w-full flex flex-col items-center ${isListingPage ? "bg-troo-light" : "bg-troo-primary-light"}`}>
			<div className="main-container w-full flex flex-col items-center gap-8">
				{
					!isListingPage && (
						<div className="w-full flex flex-wrap items-center gap-4 justify-between">
							<Heading
								alignStart
								headline="Awesome Client’s Reviews"
								title={
									<>
										Let’s check our latest project <br />
										we have recently done
									</>
								}
							/>
							<div className="flex items-center">
								<BsFillLightningChargeFill className="text-9xl text-troo-primary text-opacity-20" />
								<p className="-ml-10">
									There are many variations of passages of Lorem Ipsum available,
									but the <br className="lg:block hidden" />
									majority have suffered alteration in some form, by injected
									humour, or
									<br className="lg:block hidden" /> randomised words which don’t
									look even slightly believable.
								</p>
							</div>
						</div>
					)
				}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
					{PROJECTS.map((data, i) => {
						return (
							<div
								className={`w-full ${i === 2 && "aspect-square md:aspect-auto md:row-span-2"
									}`}
							>
								<ProjectCard data={data} />
							</div>
						);
					})}
				</div>
				{
					!isListingPage && (
						<Link to={ROUTES.portfolio}>
							<div className="p-5 bg-troo-primary rounded-lg text-troo-light text-center outline-dashed outline-2 outline-offset-8 mt-8 outline-troo-primary">
								<p className="font-bold font-secondary text-2xl">
									Want See More Project?
								</p>
								<p className="font-secondary underline underline-offset-4 mt-2">
									View All Projects
								</p>
							</div>
						</Link>
					)
				}
			</div>
		</div>
	);
};

export default ProjectSection;
