import React from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";

const ListItem = ({ txt }) => {
	return (
		<div className="flex items-center gap-2">
			<BsFillPatchCheckFill className="text-troo-primary text-2xl" />
			<p>{txt}</p>
		</div>
	);
};

export default ListItem;
