import React from "react";
import PersonImg from "../assets/images/Person.png";
import TotalCharge from "../assets/images/Total-Charge.png";

const ContactBanner = () => {
	return (
		<div className="w-full bg-contact-banner bg-cover bg-center bg-no-repeat">
			<div className="text-troo-secondary bg-black opacity-90  py-[100px] w-full flex flex-col items-center">
				<div className="main-container-big w-full flex flex-col items-center gap-12">
					<p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-troo-light text-center">
						Don’t wait and start
						<br /> your own project with us today
					</p>
					<div className="flex items-center gap-5 md:gap-8">
						<div className="flex items-center gap-3">
							<div className="flex text-troo-light flex-col items-end">
								<h3>10k+</h3>
								<p className="text-xs sm:text-sm md:text-base">Happy users</p>
							</div>
							<img src={PersonImg} alt="person" className="md:w-auto sm:w-14 w-12" />
						</div>
						<div className="w-[1px] bg-troo-light bg-opacity-30 h-16 " />
						<div className="flex items-center gap-3">
							<img src={TotalCharge} alt="person" className="md:w-auto sm:w-14 w-12" />
							<div className="flex text-troo-light flex-col items-start">
								<h3>50k+</h3>
								<p className="text-xs sm:text-sm md:text-base">Charger Installed</p>
							</div>
						</div>
					</div>
					<p className="text-troo-light text-sm sm:text-base lg:text-lg text-center">
						Lorem ipsum dolor sit amet consectetur adipiscing elit. Donec felis
						suscipit mi
						<br /> urna nulla at tincidunt feugiat vulputate. Ante facilisis
						face pellentesque.
					</p>
					<div className="flex flex-wrap justify-center items-center gap-4">
						<button className="btn-theme-primary uppercase font-bold">
							Start Free Trial
						</button>
						<button className="btn-theme-primary uppercase font-bold">
							Contact With Us
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactBanner;
