import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import AboutSection from '../sections/AboutSection'
import SolutionSection from '../sections/SolutionSection'
import ContactBanner from '../sections/ContactBanner'
import TeamSection from '../sections/TeamSection'
import TestimonialSection from '../sections/TestimonialSection'
import VideoBannerSection from '../sections/VideoBannerSection'
import BrandSection from '../sections/BrandSection'

const About = () => {
  return (
    <Layout>
      <SubHeroSection title="About Us" />
      <AboutSection />
      <SolutionSection />
      <ContactBanner />
      <TeamSection />
      <TestimonialSection />
      <VideoBannerSection />
      <BrandSection />
    </Layout>
  )
}

export default About