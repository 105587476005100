import React from "react";
import { motion } from "framer-motion";
import { BsFillLightningChargeFill } from "react-icons/bs";

const Heading = ({ title, headline, alignStart, light }) => {
	return (
		<motion.div
			initial={{ scale: 0.7 }}
			whileInView={{ scale: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }}
			className={`flex flex-col gap-5 ${alignStart ? "items-start" : "items-center"
				} ${light ? "text-troo-light" : "text-troo-tertiary"}`}
		>
			<div className={`flex gap-3 items-center`}>
				<span className="text-2xl text-troo-primary">
					<BsFillLightningChargeFill />
				</span>
				<p className="text-sm lg:text-lg">{headline}</p>
			</div>
			<h2 className={`${alignStart ? "text-start" : "text-center"}`}>
				{title}
			</h2>
		</motion.div>
	);
};

export default Heading;
