import React from "react";
import { motion } from "framer-motion";

const TeamCard = ({ data }) => {
	return (
		<motion.div initial={{ y: 100 }}
			whileInView={{ y: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="w-full relative overflow-hidden rounded-lg">
			<img
				src={data?.img}
				alt={data?.title}
				className="w-full h-full object-cover object-center"
			/>
			<motion.div initial={{ paddingBottom: "0px" }}
				whileHover={{ paddingBottom: "24px" }}
				transition={{ duration: 1, type: "spring", stiffness: 100 }} className="absolute top-0 left-0 w-full h-full px-6 gap-4 flex flex-col items-center justify-end">
				<div className="bg-troo-light rounded-lg p-4 text-center gap-3 flex flex-col items-center w-full">
					<p className="bg-troo-secondary text-troo-light px-4 py-2 rounded-md md:text-base sm:text-sm text-xs">
						{data?.designation}
					</p>
					<h4 className=" font-bold font-secondary">{data?.title}</h4>
				</div>
			</motion.div>
		</motion.div>
	);
};

export default TeamCard;
