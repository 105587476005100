import React from 'react'
import Layout from '../components/Layout'
import ServiceImg from "../assets/images/service-details.png"
import ListItem from '../components/ListItem'
import ServiceImg2 from "../assets/images/project-4.png"
import ListItemWithNum from '../components/ListItemWithNum'
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import SubHeroSection from '../components/SubHeroSection'

const ServiceDetails = () => {
  return (
    <Layout>
      <SubHeroSection title="Service Details" />
      <div className="text-troo-secondary py-[100px] w-full flex flex-col items-center">
        <div className="main-container-big bg-troo-light w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
          <div className='flex flex-col items-start w-full gap-4'>
            <div className="w-full bg-troo-primary-light rounded-lg p-8 flex flex-col items-start gap-3">
              <h5>Service Categories</h5>
              <div className="w-20 h-[2px] bg-troo-primary" />
              <ListItemWithNum no="01" txt="Public Charging" />
              <ListItemWithNum no="02" txt="Commercial Systems" />
              <ListItemWithNum no="03" txt="Car Refueling" />
              <ListItemWithNum no="04" txt="EV & Hybrid Repair" />
              <ListItemWithNum no="05" txt="Building Services" />
              <ListItemWithNum no="06" txt="Public Charging" />
              <ListItemWithNum no="07" txt="Support & Maintenance" />
            </div>
            <div className="w-full bg-troo-primary flex flex-col items-center text-center rounded-lg p-8 gap-6">
              <h4 className='text-troo-light'>
                If You Need Any Help
                Contact With Us
              </h4>
              <div className="w-40 h-[1px] bg-troo-light" />
              <div className='w-full bg-troo-secondary rounded-lg text-troo-light p-5 flex items-center gap-4'>
                <HiOutlineChatBubbleLeftRight className='text-2xl sm:text-4xl' />
                <div className='text-start text-sm sm:text-base '>
                  <p className='font-secondary'>+44 123 456 7890</p>
                  <p className='mt-1'>contact@trooevsolotion.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 order-1 w-full flex flex-col items-start gap-4">
            <img src={ServiceImg} className="w-full rounded-xl" alt="car" />
            <h5>We give the best Services</h5>
            <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simply free Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua lonm andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua lonm andhn.</p>
            <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore simply free Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simpl.</p>
            <h5>Service Process</h5>
            <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore simply free Lorem ipsum dolor.</p>
            <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-5'>
              <div className='w-full flex items-start gap-3 p-6 border-t border-troo-primary bg-troo-light shadow-2xl'>
                <p className='text-xl font-bold font-secondary aspect-square p-3 w-12 h-12 flex items-center justify-center rounded-full bg-troo-primary bg-opacity-20 text-troo-primary'>01</p>
                <div className='flex flex-col items-start gap-4 mt-3'>
                  <h5>Creative Analysis</h5>
                  <p>Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet.</p>
                </div>
              </div>
              <div className='w-full flex items-start gap-3 p-6 border-t border-troo-primary bg-troo-light shadow-2xl'>
                <p className='text-xl font-bold font-secondary aspect-square p-3 w-12 h-12 flex items-center justify-center rounded-full bg-troo-primary bg-opacity-20 text-troo-primary'>02</p>
                <div className='flex flex-col items-start gap-4 mt-3'>
                  <h5>Design & Scatches</h5>
                  <p>Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet.</p>
                </div>
              </div>
              <div className='w-full flex items-start gap-3 p-6 border-t border-troo-primary bg-troo-light shadow-2xl'>
                <p className='text-xl font-bold font-secondary aspect-square p-3 w-12 h-12 flex items-center justify-center rounded-full bg-troo-primary bg-opacity-20 text-troo-primary'>03</p>
                <div className='flex flex-col items-start gap-4 mt-3'>
                  <h5>Prototype & Wireframing</h5>
                  <p>Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet.</p>
                </div>
              </div>
              <div className='w-full flex items-start gap-3 p-6 border-t border-troo-primary bg-troo-light shadow-2xl'>
                <p className='text-xl font-bold font-secondary aspect-square p-3 w-12 h-12 flex items-center justify-center rounded-full bg-troo-primary bg-opacity-20 text-troo-primary'>04</p>
                <div className='flex flex-col items-start gap-4 mt-3'>
                  <h5>Delivery & Deploy</h5>
                  <p>Lorem ipsum dolor sit amet it just dummy content, consectetur Lorem ipsum dolor sit amet.</p>
                </div>
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt ut labore simply free Lorem ipsum.</p>
            <div className='w-full grid md:grid-cols-2 items-center gap-6'>
              <img src={ServiceImg2} className="w-full rounded-lg" alt="car" />
              <div className='w-full flex flex-col items-start gap-4'>
                <h5>Why Choose This Service</h5>
                <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor incididunt.</p>
                <ListItem txt="Easy to Use, Built Technology" />
                <ListItem txt="Easy to Built onPowerful Technology" />
                <ListItem txt="Easy to Use, Built Technology" />
                <ListItem txt="Easy to Use, Built Technology" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServiceDetails