import React from "react";
import Heading from "../components/Heading";
import EVStation from "../assets/images/ev-station.png";
import SolutionCard from "../components/SolutionCard";

const SolutionSection = () => {
	return (
		<div className="text-troo-secondary bg-whyUs bg-troo-primary-light  py-[100px] w-full flex flex-col items-center">
			<div className="main-container-big w-full flex flex-col items-center gap-8">
				<Heading
					headline="People Why Choose TRoo Themes"
					title={
						<>
							Convenient and reliable solutions for
							<br /> smart electric car charging
						</>
					}
				/>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
					<div className="flex flex-col items-start gap-5">
						<div className="">
							<SolutionCard id="01" title="Fast Charging" />
						</div>
						<div>
							<SolutionCard id="02" title="Renewable Energy" />
						</div>
						<div>
							<SolutionCard id="03" title="Management System" />
						</div>
					</div>
					<div className="hidden lg:block">
						<img
							src={EVStation}
							alt="EVStation"
							className="w-full h-full object-contain"
						/>
					</div>
					<div className="flex flex-col items-start gap-5">
						<div className="">
							<SolutionCard id="04" title="High Quality Charger" />
						</div>
						<div>
							<SolutionCard id="05" title="No Upfront Payment" />
						</div>
						<div>
							<SolutionCard id="06" title="Management System" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SolutionSection;
