import React from "react";
import Logo from "../../shared/assets/images/logo-light.svg";
import Footer2 from "../../shared/assets/images/footer-2.png";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker, HiOutlineMailOpen } from "react-icons/hi";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
	return (
		<div className="bg-troo-secondary bg-footer bg-cover bg-no-repeat text-troo-light bg-center w-full">
			<div className="w-full bg-troo-secondary bg-opacity-90 flex flex-col">
				<div className="main-container flex gap-8 flex-col items-center w-full">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 rounded-b-3xl overflow-hidden">
						<div className="w-full bg-troo-primary flex flex-col items-start gap-4 p-6 md:p-12 lg:p-20 justify-center order-last lg:order-first">
							<h3 className="text-troo-secondary">Newsletter</h3>
							<p className="">
								There are many variations of passages of Lorem Ipsum available,
								but the majority have suffered.
							</p>
							<div className="flex md:flex-row flex-col w-full items-center gap-2">
								<div className="px-2 md:px-4 py-1 md:py-2 rounded-lg bg-troo-light outline-none flex items-center justify-between gap-2 w-full">
									<input
										type="email"
										placeholder="Email"
										className="bg-transparent border-none w-full outline-none"
									/>
								</div>
								<button className="btn-theme-secondary md:w-auto w-full">
									Subscribe Now!
								</button>
							</div>
						</div>
						<div className="w-full h-full">
							<img
								src={Footer2}
								alt="car"
								className="w-full h-full object-cover object-center"
							/>
						</div>
					</div>
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-10 my-8">
						<div className="flex flex-col items-start gap-6">
							<img src={Logo} alt="brand logo" className="w-48 md:w-64" />
							<div className="p-7 w-full rounded-md border border-troo-primary flex xl:flex-row flex-col xl:items-center justify-between gap-3">
								<div className="flex items-center gap-4">
									<FiPhoneCall className="text-4xl text-troo-primary" />
									<div className="flex flex-col items-start">
										<p>Call Us on</p>
										<h4>+44 123 456 7890</h4>
									</div>
								</div>
								<div className="h-full w-[1px] bg-troo-light xl:block hidden" />
								<div className="flex items-center gap-4">
									<HiOutlineMailOpen className="text-4xl text-troo-primary" />
									<div className="flex flex-col items-start">
										<p>Email</p>
										<h4>info@troothemes@com</h4>
									</div>
								</div>
							</div>
						</div>
						<div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
							<div className="">
								<h5>About</h5>
								<div className="mt-6 flex flex-col items-start gap-4">
									<p>About Us</p>
									<p>Blog</p>
									<p>Case Studies</p>
									<p>Contact Us</p>
									<p>FAQs</p>
								</div>
							</div>
							<div className="">
								<h5>Our Solutions</h5>
								<div className="mt-6 flex flex-col items-start gap-4">
									<p>Home Charging</p>
									<p>Public Charging</p>
									<p>Commercial Solution</p>
									<p>Battery Maintenance</p>
									<p>Drivers & Personal Use</p>
								</div>
							</div>
							<div className="">
								<h5>Our Location</h5>
								<div className="mt-6 flex flex-col items-start gap-4">
									<div className="flex items-start gap-3">
										<HiOutlineLocationMarker className="text-4xl" />
										<p>4516 School Street Danbury, CT, Canada</p>
									</div>
									<div className="flex flex-wrap items-center gap-3">
										<div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-light">
											<FaFacebookF />
										</div>
										<div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-light">
											<FaXTwitter />
										</div>
										<div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-light">
											<FaLinkedinIn />
										</div>
										<div className="text-xl p-3 rounded-full aspect-square flex items-center justify-center bg-troo-primary bg-opacity-10 text-troo-light">
											<FaInstagram />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="main-container flex bg-troo-primary bg-opacity-10 gap-8 flex-col items-center w-full mt-6">
					<div className="py-3 rounded-t-md text-troo-light px-6 w-full flex lg:flex-row flex-col items-center justify-between gap-4 text-sm">
						<p className="lg:w-full text-center lg:text-left">
							Copyright © 2023. troothemes. All rights reserved.
						</p>
						<div className="lg:w-full flex flex-wrap items-center lg:justify-end gap-5">
							<p>Terms & Conditions</p>
							<p>Cookie Policy</p>
							<p>Privacy Policy</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
