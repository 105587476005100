import React from "react";
import Layout from "../components/Layout";
import HeroSection from "../sections/HeroSection";
import AboutSection from "../sections/AboutSection";
import SolutionSection from "../sections/SolutionSection";
import ServiceSection from "../sections/ServiceSection";
import ProjectSection from "../sections/ProjectSection";
import HowItWorks from "../sections/HowItWorks";
import ContactBanner from "../sections/ContactBanner";
import TeamSection from "../sections/TeamSection";
import TestimonialSection from "../sections/TestimonialSection";
import VideoBannerSection from "../sections/VideoBannerSection";
import BlogSection from "../sections/BlogSection";
import BrandSection from "../sections/BrandSection";

const Home = () => {
	return (
		<Layout>
			<HeroSection />
			<AboutSection />
			<SolutionSection />
			<ServiceSection />
			<ProjectSection />
			<HowItWorks />
			<ContactBanner />
			<TeamSection />
			<TestimonialSection />
			<VideoBannerSection isAppDownloadBanner />
			<BlogSection />
			<BrandSection />
		</Layout>
	);
};

export default Home;
