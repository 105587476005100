import React from 'react'
import Heading from '../components/Heading'
import { BLOGS } from '../constants'
import BlogCard from '../components/BlogCard'
import { ROUTES } from '../constants/routes'
import { Link } from 'react-router-dom'

const BlogSection = ({ isListingPage }) => {
  return (
    <div className="text-troo-secondary w-full flex flex-col items-center">
      <div className={`main-container-big py-[100px] w-full flex flex-col items-center gap-8 ${isListingPage ? "bg-troo-light" : "bg-troo-primary-light"}`}>
        {
          !isListingPage && (
            <Heading headline="News & Articles" title="Here we’ll share some tips & tricks" />
          )
        }
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {BLOGS.map((data, i) => {
            return (
              <BlogCard data={data} index={i} />
            )
          })}
        </div>
        {
          !isListingPage && (
            <Link to={ROUTES.ourBlogs}>
              <button className="btn-theme-secondary mt-8">View All blogs</button>
            </Link>
          )}
      </div>
    </div>
  )
}

export default BlogSection