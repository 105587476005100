import React from "react";
import Heading from "../components/Heading";
import { TEAM } from "../constants";
import TeamCard from "../components/TeamCard";

const TeamSection = ({ isListingPage }) => {
	return (
		<div className="text-troo-secondary bg-transparent py-[100px] w-full flex flex-col items-center">
			<div className="main-container-big">
				<div className="w-full bg-troo-light flex flex-col items-center gap-8">
					{
						!isListingPage && (
							<Heading
								headline="We Have Powerful Team"
								title={
									<>
										We picked best specialists that are
										<br /> suited just for you
									</>
								}
							/>
						)
					}
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
						{TEAM.map((data) => {
							return <TeamCard data={data} />;
						})}
					</div>
					{
						!isListingPage && (
							<div className="px-4 py-3 rounded-md text-troo-secondary border border-troo-secondary border-dashed text-center">
								Want to see more all member?{" "}
								<span className="underline underline-offset-4 font-bold">
									Click Here
								</span>
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default TeamSection;
