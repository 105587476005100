import React from 'react'
import ErrorImg from "../assets/images/error-img.png"

const ErrorPage = () => {
  return (
    <div className="w-full min-h-screen px-10 py-20 flex flex-col items-center justify-center gap-10">
      <img src={ErrorImg} alt="404" className='w-full md:w-[60%]' />
      <p className="text-troo-secondary text-center text-sm md:text-base">The page you look for does not exist or another error occurred<br className='md:block hidden' />
        please kindly refreash or retun to home page.</p>
      <div className="flex items-center gap-2 flex-wrap justify-center">
        <button className="btn-theme-primary">BACK TO HOMEPAGE</button>
        <button className="btn-theme-secondary">GO BACK</button>
      </div>
    </div>
  )
}

export default ErrorPage