import React from "react";
import Heading from "../components/Heading";
import { TESTIMONIAL } from "../constants";
import TestimonialCard from "../components/TestimonialCard";

const TestimonialSection = () => {
	return (
		<div className="text-troo-secondary bg-testimonial bg-center bg-troo-primary-light py-[100px] w-full flex flex-col items-center">
			<div className="main-container-big  w-full flex flex-col items-center gap-8">
				<div className="w-full flex flex-wrap items-center justify-between gap-8">
					<Heading
						alignStart
						headline="Awesome Client’s Reviews"
						title={
							<>
								What our clients say <br />
								about our services
							</>
						}
					/>
					<button className="btn-theme-primary uppercase font-bold">
						View All Reviews
					</button>
				</div>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-20 mt-16">
					{TESTIMONIAL.map((data) => {
						return <TestimonialCard data={data} />;
					})}
				</div>
			</div>
		</div>
	);
};

export default TestimonialSection;
