import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import portfolioImg from "../assets/images/portolio-details.png"
import { PROJECTS } from '../constants'
import ProjectCard from '../components/ProjectCard'
import { GoArrowUpLeft, GoArrowRight } from "react-icons/go";

const PortfolioDetails = () => {
  return (
    <Layout>
      <SubHeroSection title="Portfolio Details" />
      <div className="text-troo-secondary py-[100px] w-full">
        <div className="main-container-big bg-troo-light w-full flex flex-col items-start gap-6">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 w-full rounded-xl overflow-hidden">
              <img src={portfolioImg} alt="car" className="w-full h-full object-cover" />
            </div>
            <div className="w-full bg-troo-primary-light rounded-lg p-8 flex flex-col items-start gap-4">
              <h5>Project info</h5>
              <div className="w-20 h-[2px] bg-troo-primary" />
              <div className='w-full flex items-center justify-between gap-3'>
                <p className='font-bold'>Date</p>
                <p>14 June 2023</p>
              </div>
              <div className='w-full flex items-center justify-between gap-3'>
                <p className='font-bold'>Client</p>
                <p>John Carmen</p>
              </div>
              <div className='w-full flex items-center justify-between gap-3'>
                <p className='font-bold'>Category</p>
                <p>Charging Stration</p>
              </div>
              <div className='w-full flex items-center justify-between gap-3'>
                <p className='font-bold'>Project</p>
                <p>Ecofuel</p>
              </div>
              <div className='w-full flex items-center justify-between gap-3'>
                <p className='font-bold'>Service</p>
                <p>Charging</p>
              </div>
            </div>
          </div>
          <h5>Overview</h5>
          <p>Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod tempor  incididunt ut labore et simply free text dolore magna aliqua lonm andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit sed do eiusm incididunt ut labore et simply free text dolore magna aliqua lonm andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit sed do eiusmod tempor incididunt ut labore et simply free text dolore magna aliqua lonm andhn.od tempor incididunt ut labore et simply free text dolore magna aliqua lonm.</p>
          <h5>The Challenges</h5>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
          <h5>The Solutions</h5>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
          <h5>Image Galary</h5>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {PROJECTS.map((data, i) => {
              return (
                <div
                  className={`w-full ${i === 2 && "aspect-square md:aspect-auto md:row-span-2"
                    }`}
                >
                  <ProjectCard data={data} isImageOnly />
                </div>
              );
            })}
          </div>
          <div className="w-full flex items-start gap-4 justify-between py-5 mt-8 border-t border-troo-primary border-opacity-50">
            <div className='flex items-start gap-4 text-xs sm:text-sm md:text-base'>
              <GoArrowUpLeft className='text-base sm:text-lg md:text-xl' />
              <div className='flex flex-col items-start gap-1'>
                <p>Preview Project</p>
                <p className="font-bold">Mobile App Design</p>
              </div>
            </div>
            <div className='flex items-start gap-4'>
              <div className='flex flex-col items-end gap-1 text-xs sm:text-sm md:text-base'>
                <p>Next Project</p>
                <p className="font-bold text-troo-primary">Website Redesign</p>
              </div>
              <GoArrowRight className='text-base sm:text-lg md:text-xl' />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PortfolioDetails