import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import { FiPhone } from "react-icons/fi";
import { HiOutlineMailOpen, HiLocationMarker } from "react-icons/hi";
import { IoTimeOutline } from "react-icons/io5";
import Heading from '../components/Heading';


const ContactUs = () => {
  return (
    <Layout>
      <SubHeroSection title="Get In Touch" />
      <div className="w-full flex flex-col items-center">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.993031058488!2d72.7875218!3d21.1924358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ddfbf486b7d%3A0x4efa1199e384241!2sTRooInbound%20Pvt.%20Ltd.%20%7CTop%20Rated%20WordPress%20and%20Expert%20Shopify%20Development%20Agency%20-%20Surat!5e0!3m2!1sen!2sin!4v1700478300169!5m2!1sen!2sin" width="100%" height="550" style={{ border: 0 }} title="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div className="main-container-big bg-transparent w-full -mt-20">
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-4 gap-4 bg-troo-light p-12 rounded-md shadow-2xl">
            <div className='w-full flex flex-col sm:items-center lg:border-r border-troo-secondary border-opacity-10'>
              <div className='flex flex-col items-start gap-3'>
                <div className='text-2xl text-troo-primary bg-troo-primary bg-opacity-10 p-4 rounded-full'>
                  <FiPhone />
                </div>
                <p className='text-base'>Need Help? Call Us on</p>
                <div>
                  <p className='font-bold text-base xl:text-xl font-secondary'>+44 123 456 7890</p>
                  <p className='font-bold font-secondary text-base xl:text-xl'>+44 123 456 7890</p>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col sm:items-center lg:border-r border-troo-secondary border-opacity-10'>
              <div className='flex flex-col items-start gap-3'>
                <div className='text-2xl text-troo-primary bg-troo-primary bg-opacity-10 p-4 rounded-full'>
                  <HiOutlineMailOpen />
                </div>
                <p className='text-base'>Email Us</p>
                <div>
                  <p className='font-bold font-secondary text-base xl:text-xl'>info@troothems.com</p>
                  <p className='font-bold font-secondary text-base xl:text-xl'>contact@troothems.com</p>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col sm:items-center lg:border-r border-troo-secondary border-opacity-10'>
              <div className='flex flex-col items-start gap-3'>
                <div className='text-2xl text-troo-primary bg-troo-primary bg-opacity-10 p-4 rounded-full'>
                  <HiLocationMarker />
                </div>
                <p className='text-base'>Our Office Address</p>
                <div>
                  <p className='font-bold font-secondary text-base xl:text-xl'>4516 School Street </p>
                  <p className='font-bold font-secondary text-base xl:text-xl'>Danbury, CT, Canada</p>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col sm:items-center'>
              <div className='flex flex-col items-start gap-3'>
                <div className='text-2xl text-troo-primary bg-troo-primary bg-opacity-10 p-4 rounded-full'>
                  <IoTimeOutline />
                </div>
                <p className='text-base'>Opening Hours</p>
                <div>
                  <p className='font-bold font-secondary text-base xl:text-xl'>Mon-Sat: 10 am to 6 pm</p>
                  <p className='font-bold font-secondary text-base xl:text-xl'>Sunday: Closed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-container-big bg-transparent py-[100px] w-full flex flex-col items-center gap-8">
          <Heading headline="People Why Choose TRoo Themes" title={<>Need Any Further Information,<br />
            Please Feel Free to Reach Out to Us</>} />
          <div className="w-full flex flex-col items-start gap-8">
            <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-6 mt-10'>
              <div className='flex flex-col items-start gap-3'>
                <p className='text-sm md:text-base'>Full Name</p>
                <input type='text' placeholder='Full Name' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
              <div className='flex flex-col items-start gap-3'>
                <p className='text-sm md:text-base'>Phone Number</p>
                <input type='text' placeholder='Phone Number' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
              <div className='flex flex-col items-start gap-3'>
                <p className='text-sm md:text-base'>Email Address</p>
                <input type='text' placeholder='Email Address' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
              <div className='flex flex-col items-start gap-3 md:col-span-2 lg:col-span-3'>
                <p className='text-sm md:text-base'>Message</p>
                <textarea rows={6} placeholder='Message' className='w-full px-4 py-3 md:py-5 md:text-base text-sm rounded-md bg-troo-primary-light border border-troo-secondary border-opacity-20' />
              </div>
            </div>
            <button className='btn-theme-primary'>POST COMMENT</button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs