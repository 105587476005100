import React from "react";
import Heading from "../components/Heading";
import { SERVICE } from "../constants";
import ServiceCard from "../components/ServiceCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ServiceSection = ({ isListingPage }) => {
	return (
		<div className={`w-full ${!isListingPage && "bg-service-section bg-cover bg-center bg-no-repeat"}`}>
			<div className={`text-troo-secondary bg-opacity-80 py-[100px] w-full flex flex-col items-center ${isListingPage ? "bg-troo-light" : "bg-troo-secondary"}`}>
				<div className="main-container-big w-full flex flex-col items-center gap-12">
					{
						!isListingPage && (
							<Heading
								headline="What We Do For You"
								title={
									<>
										Best electric charger solutions for
										<br /> the electric vehicle
									</>
								}
								light
							/>
						)
					}
					<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
						{SERVICE?.map((data, i) => {
							return <ServiceCard data={data} />;
						})}
					</div>
					{
						!isListingPage && (
							<Link to={ROUTES.ourServices}>
								<button className="btn-theme-primary">View more services</button>
							</Link>
						)}
				</div>
			</div>
		</div>
	);
};

export default ServiceSection;
