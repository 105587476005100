import React from 'react'
import Layout from '../components/Layout'
import SubHeroSection from '../components/SubHeroSection'
import ProjectSection from '../sections/ProjectSection'

const Portfolio = () => {
  return (
    <Layout>
      <SubHeroSection title="Our Recently Works" />
      <ProjectSection isListingPage />
    </Layout>
  )
}

export default Portfolio